body{
  font-family: 'Roboto', sans-serif;
  margin: 0px;
  background:#f5f5f5;
}

.MuiButton-containedPrimary{background-color: #0070f3 !important; }

.dot.jsx-2885887590{background-color:'red' !important;}

.MuiInput-underline::after{
  border-bottom: 2px solid rgb(66, 66, 66) !important;
}
.MuiFormLabel-root.Mui-focused{
color: rgb(66, 66, 66) !important;
}

.MuiTab-textColorPrimary.Mui-selected{
  color: rgb(66, 66, 66) !important;
  
}


#desc{display:block}
#mobile{display:none}

@media (max-width: 667px) {
  #desc{display:none}
  #mobile{display:block}
  
}




.container{
  padding:10px;
  /* display: flex; */   
  overflow-x: auto; 
  /* background: #ddd; */
  /* border:1px solid red; */
}
.footerColor p a{color:#9e9e9e}
small{color:grey;}
/* .container img{display:"none"} */
.procedur{color:#000}

li.MuiListItem-root > h4{
  color:rgb(63, 212, 173);
  opacity:.2;
}
.MuiPopover-paper{
  width:155px;
  margin-top:43px;
}
.chips{
  border:1px solid grey;
  padding:3px;
  margin:2px;
  border-radius: 9px;
  font-size: 12px;
  color:grey;
}
.MuiSwitch-colorSecondary.Mui-checked{
  color:#0070f3;
}

.AiList:hover{background:#eee}

.MuiListItem-secondaryAction {
  padding-right: 98px !important;
}

.MuiCardContent-root{
  padding: 8px !important;
}
.ordinal-labels{
  font-size: 12px !important;
  fill:grey !important;
}
.MuiTabs-indicator{background-color:#222 !important;}
.MuiListSubheader-inset{
  padding-left: 200px !important;
}

.tooltip-content {
  background: white;
  position: relative;
  border: 1px solid #ddd;
  color: black;
  padding: 10px;
  z-index: 100;
  transform: translateX(-50%) translateY(5px);
  min-width: 120px;
}

.voronoi path {
	fill: #f5f5f5;
	/* stroke: #000; */
	/* stroke-width: 1px; */
}

.axisTitle{font-size: 10px;fill:grey}
.axis text{font-size: 10px;fill:grey}

.simpleSpinner {
  margin-right:5px;
  height: 16px;
  width: 16px;
  border: 3px solid #eee;
  border-radius: 50%;
  border-top-color: rgb(63, 212, 173);
  animation: rotate 1s 0s infinite linear normal;
}
@keyframes rotate {
  0%   { transform: rotate(0);      }
  100% { transform: rotate(360deg); }
}

circle:hover{opacity:.5}
.circles{fill:'red'}